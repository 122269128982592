import AppSettingsForm from "@/apps/list/app/common/settings/AppSettingsForm"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON, APP_KIND_LABELS } from "@/apps/util/appConstants"
import { DashboardLayout } from "@/apps/util/hooks/__generated__/useAddAppFormStoreMutation.graphql"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import oneColumnImage from "@/core/ui/images/dashboard-layout-1-column.png"
import twoColumnImage from "@/core/ui/images/dashboard-layout-2-column.png"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoFormControl, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { generatePath, useHistory } from "react-router-dom"

interface Props extends TestIDProps {
  appId?: GlobalID
}

function DashboardAppSetupModalContent({
  testid = "DashboardAppSetupModalContent",
  appId,
}: Props) {
  const { closeModal, setOpenModalState } = useActiveAppModal()
  const classes = useStyles()
  const history = useHistory()

  const createForm = useAddAppFormStore({
    kind: "dashboard",
    badge: {
      kind: "icon",
      icon: APP_KIND_ICON.dashboard,
      color: "#ffffff00",
    },
    customAppTitle: "",
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
    dashboardLayout: "one_column",
  })
  const editForm = useEditAppFormStore(appId)
  const form = appId ? editForm : createForm

  return (
    <AppSettingsForm
      form={form}
      onSubmit={appId ? handleEditApp : handleAddApp}
      onClose={appId ? closeModal : () => setOpenModalState({ kind: "add-app" })}
      mode={appId ? "edit" : "add"}
      formSectionBody={
        <DiscoFormControl
          label={<DiscoText variant={"body-sm"}>{"Page Layout"}</DiscoText>}
        >
          <RadioGroup
            value={form.state.dashboardLayout}
            onChange={(_, v) => (form.state.dashboardLayout = v as DashboardLayout)}
            className={classes.radioGroup}
          >
            <DiscoRadioWithImage
              testid={`${testid}.layout.one-column`}
              label={"1-column layout"}
              sublabel={<DiscoImage src={oneColumnImage} className={classes.img} />}
              value={"one_column"}
              checked={form.state.dashboardLayout === "one_column"}
            />
            <DiscoRadioWithImage
              testid={`${testid}.layout.two-column`}
              label={"2-column layout"}
              sublabel={<DiscoImage src={twoColumnImage} className={classes.img} />}
              value={"two_column"}
              checked={form.state.dashboardLayout === "two_column"}
            />
          </RadioGroup>
        </DiscoFormControl>
      }
    />
  )

  async function handleAddApp() {
    const {
      embedCode: _embedCode,
      scormFileId: _scormFileId,
      ...state
    } = createForm.state
    const { didSave, response } = await createForm.submit(state, {
      connections: [],
      variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
    })
    if (!didSave || !response?.node) return

    displaySuccessToast({
      message: `${APP_KIND_LABELS.dashboard} app created!`,
      testid: `${testid}.success-message`,
    })
    closeModal()
    history.push(
      generatePath(ROUTE_NAMES.COMMUNITY.DASHBOARD_APP.DETAIL, {
        appId: response.node.id,
      })
    )
  }

  async function handleEditApp() {
    const {
      embedCode: _embedCode,
      scormFileId: _scormFileId,
      kind: _kind,
      appDirectoryItems: _appDirectoryItems,
      ...changedState
    } = editForm.changedState
    const { didSave, response } = await editForm.submit(
      {
        id: editForm.state.id,
        ...changedState,
        badge: editForm.state.badge,
        visibility: editForm.state.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
      },
      {
        updater: (store, { response: { node } }) => {
          // If changed from 2->1 columns apps could be moved so invalidate the dashboard
          if (changedState.dashboardLayout !== "one_column") return
          if (!node?.dashboard) return
          const dashboardRecord = store.get(node.dashboard.id)
          dashboardRecord?.invalidateRecord()
        },
      }
    )
    if (!didSave || !response?.node) return
    displaySuccessToast({
      message: `${APP_KIND_LABELS.dashboard} app updated!`,
      testid: "AddApp.success-toast",
    })
    closeModal()
  }
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  img: {
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
  },
}))

export default observer(DashboardAppSetupModalContent)
