import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { useAppLevel } from "@/apps/util/appLevelContext"
import { useLabel } from "@/core/context/LabelsContext"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"
import MemberDirectorySettingsForm from "./MemberDirectorySettingsForm"
import { MemberDirectoryAppSettingsModalFragment$key } from "./__generated__/MemberDirectoryAppSettingsModalFragment.graphql"

interface Props {
  testid: string
  appKey?: MemberDirectoryAppSettingsModalFragment$key
}

function MemberDirectoryAppSettingsModal({ testid, appKey }: Props) {
  const { openModalState, closeModal } = useActiveAppModal()

  const app = useFragment<MemberDirectoryAppSettingsModalFragment$key>(
    graphql`
      fragment MemberDirectoryAppSettingsModalFragment on ProductApp {
        id
        kind
      }
    `,
    appKey || null
  )
  const { product } = useAppLevel()

  const isOrgMemberDirectoryApp = !product

  const memberLabel = useLabel(
    isOrgMemberDirectoryApp ? "organization_member" : "product_member"
  )

  return (
    <SetupAppModal
      testid={`${testid}.feed`}
      isModalOpen={
        openModalState?.kind === "member_directory" ||
        openModalState?.kind === "members" ||
        openModalState?.kind === "organization_members"
      }
      title={`${
        app?.kind === "member_directory" ? "Member Directory" : memberLabel.plural
      } App Setup`}
      description={`Edit settings on your ${memberLabel.plural} app.`}
      body={
        <MemberDirectorySettingsForm
          testid={`${testid}.members`}
          appId={app?.id}
          onClose={closeModal}
        />
      }
    />
  )
}

export default observer(MemberDirectoryAppSettingsModal)
