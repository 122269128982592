/**
 * @generated SignedSource<<1cede9d90eff6fd4a814a486fbce2c32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppsSidebarList_ProductFragment$data = {
  readonly id: string;
  readonly productApps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly collection: {
          readonly id: string;
        } | null;
        readonly contentUsage: {
          readonly id: string;
        } | null;
        readonly customAppTitle: string | null;
        readonly customAppUrl: string | null;
        readonly feed: {
          readonly id: string;
          readonly name: string;
          readonly viewerPermissions: ReadonlyArray<string>;
          readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
        } | null;
        readonly folderApps: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly collection: {
                readonly id: string;
              } | null;
              readonly contentUsage: {
                readonly id: string;
              } | null;
              readonly customAppTitle: string | null;
              readonly customAppUrl: string | null;
              readonly feed: {
                readonly id: string;
                readonly name: string;
                readonly viewerPermissions: ReadonlyArray<string>;
                readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
              } | null;
              readonly id: string;
              readonly kind: ProductAppKind;
              readonly linkProduct: {
                readonly slug: string;
              } | null;
              readonly product: {
                readonly name: string;
                readonly slug: string;
                readonly " $fragmentSpreads": FragmentRefs<"appLevelContextFragment" | "usePermissionsFragment">;
              } | null;
              readonly productApps: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly chatChannel: {
                      readonly externalChannelId: string;
                    } | null;
                    readonly id: string;
                  };
                }>;
              } | null;
              readonly " $fragmentSpreads": FragmentRefs<"AppSidebarItemFragment" | "ChatChannelSettingsModalButtonFragment" | "ChatChannelSideBarItemFragment" | "CollectionAppSettingsButtonFragment" | "ContentAppSettingsButtonFragment" | "DashboardAppSettingsButtonFragment" | "EventsAppSettingsButtonFragment" | "FeedAppSettingsButtonFragment" | "GenericAppSettingsButtonFragment" | "LinkAppSettingsButtonFragment" | "MembersAppSettingsButtonFragment" | "NavFolderSettingsDropdownFragment">;
            };
          }>;
        } | null;
        readonly id: string;
        readonly kind: ProductAppKind;
        readonly linkProduct: {
          readonly slug: string;
        } | null;
        readonly product: {
          readonly name: string;
          readonly slug: string;
          readonly " $fragmentSpreads": FragmentRefs<"appLevelContextFragment" | "usePermissionsFragment">;
        } | null;
        readonly productApps: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly chatChannel: {
                readonly externalChannelId: string;
              } | null;
              readonly id: string;
            };
          }>;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"AppSidebarItemFragment" | "ChatChannelSettingsModalButtonFragment" | "ChatChannelSideBarItemFragment" | "CollectionAppSettingsButtonFragment" | "ContentAppSettingsButtonFragment" | "DashboardAppSettingsButtonFragment" | "EventsAppSettingsButtonFragment" | "FeedAppSettingsButtonFragment" | "GenericAppSettingsButtonFragment" | "LinkAppSettingsButtonFragment" | "MembersAppSettingsButtonFragment" | "NavFolderSettingsDropdownFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "AppsSidebarList_ProductFragment";
};
export type AppsSidebarList_ProductFragment$key = {
  readonly " $data"?: AppsSidebarList_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppsSidebarList_ProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppTitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "usePermissionsFragment"
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Feed",
  "kind": "LinkedField",
  "name": "feed",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerPermissions",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appLevelContextFragment"
    }
  ],
  "storageKey": null
},
v9 = [
  (v0/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Collection",
  "kind": "LinkedField",
  "name": "collection",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentUsage",
  "kind": "LinkedField",
  "name": "contentUsage",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "linkProduct",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductAppNodeConnection",
  "kind": "LinkedField",
  "name": "productApps",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAppNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductApp",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatChannel",
              "kind": "LinkedField",
              "name": "chatChannel",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalChannelId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AppSidebarItemFragment"
},
v15 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ContentAppSettingsButtonFragment"
},
v16 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "LinkAppSettingsButtonFragment"
},
v17 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "FeedAppSettingsButtonFragment"
},
v18 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "GenericAppSettingsButtonFragment"
},
v19 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CollectionAppSettingsButtonFragment"
},
v20 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ChatChannelSettingsModalButtonFragment"
},
v21 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ChatChannelSideBarItemFragment"
},
v22 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "NavFolderSettingsDropdownFragment"
},
v23 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "EventsAppSettingsButtonFragment"
},
v24 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "MembersAppSettingsButtonFragment"
},
v25 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DashboardAppSettingsButtonFragment"
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfoObjectType",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "productApps"
        ]
      }
    ]
  },
  "name": "AppsSidebarList_ProductFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "productApps",
      "args": null,
      "concreteType": "ProductAppNodeConnection",
      "kind": "LinkedField",
      "name": "__AppsSidebarList_ProductFragment__productApps_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAppNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductApp",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v6/*: any*/),
                (v8/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/),
                {
                  "alias": "folderApps",
                  "args": null,
                  "concreteType": "ProductAppNodeConnection",
                  "kind": "LinkedField",
                  "name": "__AppsSidebarList_ProductFragment__folderApps_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductAppNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductApp",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v6/*: any*/),
                            (v8/*: any*/),
                            (v10/*: any*/),
                            (v11/*: any*/),
                            (v12/*: any*/),
                            (v13/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v16/*: any*/),
                            (v17/*: any*/),
                            (v18/*: any*/),
                            (v19/*: any*/),
                            (v20/*: any*/),
                            (v21/*: any*/),
                            (v22/*: any*/),
                            (v23/*: any*/),
                            (v24/*: any*/),
                            (v25/*: any*/),
                            (v26/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v27/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v28/*: any*/)
                  ],
                  "storageKey": null
                },
                (v26/*: any*/)
              ],
              "storageKey": null
            },
            (v27/*: any*/)
          ],
          "storageKey": null
        },
        (v28/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "a33a0d075922d7ff88c7080fdcf064d2";

export default node;
