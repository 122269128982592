import { DashboardAppSettingsButtonFragment$key } from "@/apps/list/app/dashboard/__generated__/DashboardAppSettingsButtonFragment.graphql"
import DashboardAppSetupModal from "@/apps/list/app/dashboard/DashboardAppSetupModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  appKey: DashboardAppSettingsButtonFragment$key
  children: OverridableDiscoButtonChildren
}

function DashboardAppSettingsButton(props: Props) {
  const { testid = "DashboardAppSettingsButton", appKey, children } = props
  const { openModalState, setOpenModalState } = useActiveAppModal()

  const app = useFragment<DashboardAppSettingsButtonFragment$key>(
    graphql`
      fragment DashboardAppSettingsButtonFragment on ProductApp {
        id
        ...DashboardAppSetupModalFragment
      }
    `,
    appKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} testid={testid}>
        {children}
      </OverridableDiscoButton>
      {openModalState?.id === app.id && (
        <DashboardAppSetupModal testid={testid} appKey={app} />
      )}
    </>
  )

  function openModal() {
    setOpenModalState({ kind: "dashboard", id: app.id })
  }
}

export default DashboardAppSettingsButton
