/**
 * @generated SignedSource<<143df53cb6d1413d261aee8792d5f10e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberDirectoryAppSettingsModalFragment$data = {
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly " $fragmentType": "MemberDirectoryAppSettingsModalFragment";
};
export type MemberDirectoryAppSettingsModalFragment$key = {
  readonly " $data"?: MemberDirectoryAppSettingsModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberDirectoryAppSettingsModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberDirectoryAppSettingsModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "f769181fbbb86e2b7459dcbec9df7139";

export default node;
