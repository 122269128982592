import MemberGroupsMultiSelect from "@/product/common/member-group/modal/components/MemberGroupsMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useLazyLoadQuery } from "react-relay"
import { MembersAppFilterMultiSelectQuery } from "./__generated__/MembersAppFilterMultiSelectQuery.graphql"

interface Props extends TestIDProps {
  onChange: (memberGroupIds: GlobalID[]) => void
  memberGroupIds: GlobalID[]
  productId?: GlobalID | null
  organizationId: GlobalID
  hideCommunityGroups?: boolean
  disabled?: boolean
}

function MembersAppFilterMultiSelect({
  testid = "MembersAppFilterMultiSelect",
  organizationId,
  productId,
  memberGroupIds,
  onChange,
  hideCommunityGroups,
  disabled,
}: Props) {
  const { product, organization } = useLazyLoadQuery<MembersAppFilterMultiSelectQuery>(
    graphql`
      query MembersAppFilterMultiSelectQuery($organizationId: ID!, $productId: ID!) {
        product: node(id: $productId) {
          ... on Product {
            id
            ...MemberGroupsMultiSelect_ProductFragment
            organization {
              ...MemberGroupsMultiSelect_OrganizationFragment
            }
          }
        }
        organization: node(id: $organizationId) {
          ... on Organization {
            id
            ...MemberGroupsMultiSelect_OrganizationFragment
          }
        }
      }
    `,
    {
      organizationId,
      productId: productId || "",
    },
    { fetchPolicy: "network-only" }
  )

  return (
    <MemberGroupsMultiSelect
      testid={`${testid}.group-select`}
      selectedGroupIds={memberGroupIds}
      setSelectedGroupIds={onChange}
      productKey={product}
      organizationKey={product?.organization || organization!}
      allowGroupSelection={["custom", "role", "default"]}
      hideAdminOnlyGroups
      hideCommunityGroups={hideCommunityGroups}
      disabled={disabled}
    />
  )
}

export default observer(MembersAppFilterMultiSelect)
