import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import AppListItem from "../../AppListItem"

interface Props {
  testid: string
}

export default function MemberDirectoryApp({ testid }: Props) {
  const { setOpenModalState } = useActiveAppModal()
  return (
    <AppListItem
      testid={`${testid}.member_directory`}
      title={"Member Directory"}
      description={"Showcase members who belongs to a selected group."}
      icon={APP_KIND_ICON.member_directory}
      onClick={handleOpenAddMembersDirectoryModal}
    />
  )

  function handleOpenAddMembersDirectoryModal() {
    setOpenModalState({
      kind: "member_directory",
    })
  }
}
