import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON, APP_KIND_LABELS } from "@/apps/util/appConstants"
import { TestIDProps } from "@utils/typeUtils"

function DashboardApp({ testid }: TestIDProps) {
  const { setOpenModalState } = useActiveAppModal()

  return (
    <AppListItem
      testid={`${testid}.dashboard`}
      title={APP_KIND_LABELS.dashboard}
      description={"Create a custom page using our building blocks."}
      icon={APP_KIND_ICON.dashboard}
      onClick={() => setOpenModalState({ kind: "dashboard" })}
    />
  )
}

export default DashboardApp
