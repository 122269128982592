import ChatChannelApp from "@/apps/list/app/chat/ChatChannelApp"
import CollectionApp from "@/apps/list/app/collections/CollectionApp"
import ContentApp from "@/apps/list/app/content/ContentApp"
import CurriculumProductApp from "@/apps/list/app/curriculum/CurriculumProductApp"
import DashboardApp from "@/apps/list/app/dashboard/DashboardApp"
import EventsApp from "@/apps/list/app/events/EventsApp"
import EventsProductApp from "@/apps/list/app/events/EventsProductApp"
import FeedApp from "@/apps/list/app/feed/FeedApp"
import GroupDirectoryApp from "@/apps/list/app/group-directory/GroupDirectoryApp"
import LinkApp from "@/apps/list/app/link/LinkApp"
import { ProductAppKind } from "@/apps/remove-app-button/__generated__/RemoveAppButtonFragment.graphql"
import useUnconnectedProductApps from "@/apps/util/hooks/useUnconnectedProductApps"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { Fragment } from "react"
import MemberDirectoryApp from "./app/members/MemberDirectoryApp"

const TEST_ID = "DiscoAppsList"
interface CustomDiscoAppsListProps {
  hideChannelApp?: boolean
}

function CustomDiscoAppsList(props: CustomDiscoAppsListProps) {
  const { multipleMembersApp } = useFeatureFlags()
  const activeOrganization = useActiveOrganization()
  const hideChannelApp = props.hideChannelApp || !activeOrganization?.isChannelsEnabled
  const classes = useStyles()
  const unconnectedProductApps = useUnconnectedProductApps()
  const { tmpGroupsDirectory } = useFeatureFlags()

  return (
    <div className={classes.container}>
      {unconnectedProductApps.map(renderProductAppCard)}
    </div>
  )

  // Product Disco Apps
  function renderProductAppCard(kind: ProductAppKind) {
    switch (kind) {
      case "curriculum":
        return <CurriculumProductApp key={"CurriculumApp"} testid={TEST_ID} />
      case "events":
        return <EventsProductApp key={"EventsApp"} testid={TEST_ID} />
      case "content":
        return (
          <Fragment key={"ContentApps"}>
            <ContentApp testid={`${TEST_ID}.page`} kind={"page"} />
            <ContentApp testid={`${TEST_ID}.custom_embed`} kind={"custom_embed"} />
          </Fragment>
        )
      case "chat_channel":
        return hideChannelApp ? null : (
          <ChatChannelApp key={"ChatChannelApp"} testid={TEST_ID} />
        )
      case "link":
        return <LinkApp key={"LinkApp"} testid={TEST_ID} />
      case "posts":
        return <FeedApp key={"FeedApp"} testid={TEST_ID} />
      case "collection":
        return <CollectionApp key={"CollectionApp"} testid={TEST_ID} />
      case "organization_events":
        return <EventsApp key={"CommunityEventsApp"} testid={TEST_ID} />
      case "dashboard":
        return <DashboardApp key={"DashboardApp"} testid={TEST_ID} />
      case "group_directory":
        if (!tmpGroupsDirectory) return null
        return <GroupDirectoryApp key={"GroupDirectoryApp"} testid={TEST_ID} />
      case "member_directory":
        return multipleMembersApp ? (
          <MemberDirectoryApp key={"MembersApp"} testid={TEST_ID} />
        ) : null
      default:
        return null
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
    gap: theme.spacing(1.5, 2),
    color: theme.palette.text.secondary,
  },
}))

export default CustomDiscoAppsList
