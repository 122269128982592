import { CreateAppInput } from "@/apps/list/app/chat/form/__generated__/ChatChannelSettingsForm_CreateMutation.graphql"
import { EditAppInput } from "@/apps/list/app/chat/form/__generated__/ChatChannelSettingsForm_UpdateMutation.graphql"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { useAddAppFormStoreMutation } from "@/apps/util/hooks/__generated__/useAddAppFormStoreMutation.graphql"
import { CreateProductAppInput } from "@/apps/util/hooks/__generated__/useAddAppMutation.graphql"
import {
  UpdateProductAppInput,
  useEditAppFormStoreMutation,
} from "@/apps/util/hooks/__generated__/useEditAppFormStoreMutation.graphql"
import { ProductAppKind } from "@/content-usage/__generated__/ContentUsageUtils_UseParentEntityContentUsageFragment.graphql"
import ErrorBoundary from "@/core/error/ErrorBoundary"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoButtonSkeleton, DiscoFormControlSkeleton, DiscoModal } from "@disco-ui"
import { ReactNode, Suspense } from "react"

// Legacy Form
export type LegacyCreateSetupAppModalFormState = CreateProductAppInput & {
  embedCode?: string
  scormFileId?: GlobalID
}
export type LegacyEditSetupAppModalFormState = UpdateProductAppInput & {
  embedCode?: string
  scormFileId?: GlobalID
  kind?: ProductAppKind
}
export type LegacyCreateSetupAppModalFormStore = FormStore<
  LegacyCreateSetupAppModalFormState,
  useAddAppFormStoreMutation
>
export type LegacyEditSetupAppModalFormStore = FormStore<
  LegacyEditSetupAppModalFormState,
  useEditAppFormStoreMutation
>

// New Form
export type CreateAppFormState = {
  app: CreateAppInput & {
    embedCode?: string
  }
}
export type EditAppFormState = {
  app: EditAppInput & {
    embedCode?: string
  }
}
export type CreateAppFormStore = FormStore<CreateAppFormState>
export type EditAppFormStore = FormStore<EditAppFormState>

interface Props {
  isModalOpen: boolean
  title: string
  description?: string
  body: ReactNode
  testid?: string
  onClose?: () => void
  onBack?: () => void
  buttons?: boolean
  subtabs?: ReactNode
  leftSideFooter?: ReactNode
}

function SetupAppModal({
  isModalOpen,
  onClose,
  onBack,
  title,
  description,
  body,
  testid = "SetupAppModal",
  buttons = true,
  subtabs,
  leftSideFooter,
}: Props) {
  const { closeModal } = useActiveAppModal()
  return (
    <DiscoModal
      isOpen={isModalOpen}
      onClose={onClose || closeModal}
      onBack={onBack}
      testid={testid}
      modalContentLabel={title}
      title={title}
      subtitle={description}
      width={"100%"}
      maxWidth={"720px"}
      buttons={buttons}
      body={
        // Add a generic skeleton here so not every app kind needs to add its own
        <ErrorBoundary>
          <Suspense fallback={<SetupAppModalSkeleton />}>{body}</Suspense>
        </ErrorBoundary>
      }
      subtabs={subtabs}
      leftSideFooter={leftSideFooter}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  split: {
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
  },
}))

export function SetupAppModalSkeleton() {
  const classes = useStyles()
  return (
    <Form
      buttons={
        <>
          <DiscoButtonSkeleton width={"75px"} />
          <DiscoButtonSkeleton width={"110px"} />
        </>
      }
    >
      <div className={classes.split}>
        <DiscoFormControlSkeleton variant={"one-column"} />
        <DiscoFormControlSkeleton variant={"one-column"} />
      </div>
      <DiscoFormControlSkeleton variant={"one-column"} />
    </Form>
  )
}

export default SetupAppModal
